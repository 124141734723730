<template>
  <v-app>
    <v-card width="650" class="mx-auto">
      <v-card-title><h5>Set New Password</h5></v-card-title>
      <v-card-text>
        <v-text-field
            filled
            type="email"
            label="Email"
            v-model="user.email"
            :readonly="true"
        />
        <v-text-field
            filled
            v-model="user.new_pw"
            :type="new_pw_type"
            label="New Password"
            :rules="new_pw_rules"
            :append-icon="new_pw_type === 'text' ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword('new_pw')"
        />
        <v-text-field
            filled
            v-model="user.confirm_pw"
            :type="conf_pw_type"
            label="Confirm New Password"
            :rules="conf_new_rules"
            :append-icon="conf_pw_type === 'text' ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword('conf_pw')"
        />
        <v-btn class="red darken-2 float-left mb-5 mt-3 btn" dark @click="submitReset">Submit</v-btn>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "SetPassword",
  data() {
    return {
      user: {
        token: atob(this.$route.params.token),
        email: atob(this.$route.params.email),
        new_pw: '',
        confirm_pw: '',
      },
      new_pw_type: 'password',
      conf_pw_type: 'password',
      new_pw_rules: [
        value => this.isPassValid(value) || 'New password must be at least 8 characters, contains at least 1 capital letter and 1 number'
      ],
      conf_new_rules: [
        value => this.checkSimilar(value) || 'New Password and Confirm New Password do not match.'
      ]
    }
  },
  methods: {
    submitReset() {
      if (this.user.new_pw.length >= 8 && this.user.confirm_pw.length >= 8) {
        if(this.isPassValid(this.user.new_pw) && this.checkSimilar(this.user.confirm_pw)){
          this.$store.dispatch('resetPassword', this.user)
              .then(res => {
                alert(res)
                this.$router.push('/login')
              })
              .catch(err => {
                alert(err)
                this.$router.push('/login')
              })
        }else{
          alert("New password must be at least 8 characters, contains at least 1 capital letter and 1 number")
          alert("New Password and Confirm New Password do not match.")
        }
      } else {
        alert("Please fill in all fields")
      }
    },
    showPassword(from) {
      if (from === 'new_pw') {
        if (this.new_pw_type === 'password') {
          this.new_pw_type = 'text'
        } else {
          this.new_pw_type = 'password'
        }
      } else if (from === 'conf_pw') {
        if (this.conf_pw_type === 'password') {
          this.conf_pw_type = 'text'
        } else {
          this.conf_pw_type = 'password'
        }
      }
    },
    isPassValid(val) {
      return val ? /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(val) : false
    },
    checkSimilar(val) {
      return val ? this.isPassValid(val) && val === this.user.new_pw : false
    }
  }
}
</script>

<style scoped>

</style>